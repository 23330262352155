import React, { PureComponent } from 'react';
import apis from '@/api/api';
import { Form, Radio, Space, Select, TreeSelect, Input, InputNumber as AntdInputNumber, DatePicker} from 'antd';
import { ProductDialog, InputNumber, Layout, Button } from '@/components';
import { COUPON_TYPE } from '@/enum-data/enum';
import { FormInstance } from 'antd/lib/form';
import WebApi from '@/web/api';
const style = require('../coupon-style.less');

const { RangePicker } = DatePicker;

export default class CouponFullMinusCreatePage extends React.PureComponent<RouterPropsType> {

  private form: FormInstance | null = null
  private productSelect = React.createRef<ProductDialog>();

  state = {
    couponType: COUPON_TYPE.SALE.value,
    visible: false,
    categories: [],
    giveMethod: 'NONE',
    getMethod: 'LIMIT',
    limitGiveCount: 0,
    limitGetCount: 1,
  };

  componentDidMount() {
    WebApi.category_list()
    .then(data => {
      this.setState({
        categories: data
      });
    });
  }

  render() {
    return <Layout.Page>
      <Layout flex1>
        <Layout.Title>优惠券设置</Layout.Title>
        <div style={{width: 420}}>
          <Form labelCol={{span: 6}}
            ref={r => this.form = r}
            initialValues={{
              couponType: COUPON_TYPE.SALE.value,
            }}
          >
            <Form.Item label="优惠券名称"
              name="couponName"
              rules={[
                {
                  required: true,
                  message: '请填写优惠券名称'
                }
              ]}>
              <Input placeholder="请填写优惠券名称"/>
            </Form.Item>

            <Form.Item label="优惠券有效期"
              name="validityDates"
              rules={[
                {
                  required: true,
                  message: '请选择有效日期'
                }
              ]}>
              <RangePicker style={{width: '100%'}}/>
            </Form.Item>

            <Form.Item label="订单金额" required>
              <div style={{display: 'flex'}}>
                <span style={{marginTop: 7, marginRight: 12}}>满</span>
                <Form.Item style={{marginBottom: 0, width: 150, marginRight: 10}} name="fullAmount" rules={[
                  {
                    required: true,
                    message: '请填写金额'
                  }
                ]}>
                  <AntdInputNumber style={{width: '100%'}} precision={2} min={0} placeholder="填写金额"/>
                </Form.Item>
                <span style={{marginTop: 7}}>元可使用</span>
              </div>
            </Form.Item>

            <Form.Item label="优惠券面值" required>
              <div style={{display: 'flex'}}>
                <span style={{marginTop: 7, marginRight: 12}}>减</span>
                <Form.Item
                  style={{marginBottom: 0, width: 150, marginRight: 10}}
                  name="minusAmount"
                  rules={[
                    {
                      required: true,
                      message: '请填写优惠券面值'
                    }
                  ]}>
                  <AntdInputNumber placeholder="填写金额" style={{width: '100%'}} precision={2} min={0}/>
                </Form.Item>
                <span style={{marginTop: 7}}>元</span>
              </div>
            </Form.Item>

            <Form.Item label="领取方式" required>
              <div>
                <div style={{height: 32, display: 'flex', alignItems: 'center'}}>
                  <Radio value="LIMIT" checked={this.state.getMethod === 'LIMIT'} onChange={e => {
                    this.setState({
                      getMethod: e.target.checked ? 'LIMIT' : 'NONE',
                      limitGetCount: e.target.checked ? 1 : 0
                    });
                  }}>
                    每人限领
                  </Radio>
                  {
                    this.state.getMethod === 'LIMIT' &&
                    <>
                      <InputNumber.Count
                        value={this.state.limitGetCount}
                        style={{width: 80, marginLeft: 5, marginRight: 10}}
                        min={1}
                        onChange={v => {
                          this.setState({
                            limitGetCount: v
                          });
                        }}
                      />
                      <span>张</span>
                    </>
                  }
                </div>
                <div style={{height: 32, display: 'flex', alignItems: 'center'}}>
                  <Radio checked={this.state.getMethod === 'NONE'}  onChange={e => {
                    this.setState({
                      getMethod: e.target.checked ? 'NONE' : 'LIMIT',
                      limitGetCount: 0
                    });
                  }}>无限制</Radio>
                </div>
              </div>
            </Form.Item>

            <Form.Item label="发放数量" required>
              <div>
                <div style={{height: 32, display: 'flex', alignItems: 'center'}}>
                  <Radio checked={this.state.giveMethod === 'NONE'} onChange={e => {
                    this.setState({
                      giveMethod: e.target.checked ? 'NONE' : 'LIMIT',
                      limitGiveCount: 0,
                    });
                  }}>无限制</Radio>
                </div>
                <div style={{height: 32, display: 'flex', alignItems: 'center'}}>
                  <Radio checked={this.state.giveMethod === 'LIMIT'} onChange={e => {
                    this.setState({
                      giveMethod: e.target.checked ? 'LIMIT' : 'NONE',
                      limitGiveCount: 1,
                    });
                  }}>
                    总共发放
                  </Radio>
                  {
                    this.state.giveMethod === 'LIMIT' &&
                    <>
                      <InputNumber.Count
                        value={this.state.limitGiveCount}
                        style={{width: 80, marginRight: 5}}
                        min={1}
                        onChange={v => {
                          this.setState({
                            limitGiveCount: v
                          });
                        }}
                      />
                      <span>张</span>
                    </>
                  }
                </div>
              </div>
            </Form.Item>

            <Form.Item label="优惠券类型" name="couponType" required>
              <Radio.Group style={{paddingTop: 8}} onChange={e => {
                this.setState({
                  couponType: e.target.value
                });
              }}>
                <Space direction="vertical">
                  {
                    Object.keys(COUPON_TYPE).map(key => {
                      return <Radio key={key} value={key}>{COUPON_TYPE[key].name}</Radio>
                    })
                  }
                </Space>
              </Radio.Group>
            </Form.Item>

            {
              this.state.couponType === 'CATEGORY' &&
              <Form.Item label="适用范围" name="categoryName" rules={[
                {
                  required: true,
                  message: '品类不能为空！'
                },
              ]}>
                <TreeSelect
                  treeDefaultExpandAll
                  style={{width: '100%'}}
                  dropdownMatchSelectWidth={216}
                  placeholder="选择商品分类"
                  treeData={[{
                    title: '全部分类',
                    value: '',
                    children: this.state.categories.map((i: CategoryType) => {
                      return {
                        title: i.categoryName,
                        value: i.categoryId,
                        children: i.children.map(c => {
                          return {
                            title: c.categoryName,
                            value: c.categoryId,
                          };
                        })
                      };
                    })
                  }]}
                  onChange={(value, label) => {
                    this.form?.setFieldsValue({
                      categoryName: label,
                      categoryId: value,
                    });
                  }}
                />
              </Form.Item>
            }
            <Form.Item style={{display: 'none'}} name="categoryId">
              <Input/>
            </Form.Item>

            {
              this.state.couponType === 'PRODUCT' &&
              <Form.Item label="适用范围" name="productName" rules={[
                {
                  required: true,
                  message: '商品不能为空！'
                },
              ]}>
                <Select
                  style={{width: '100%'}}
                  placeholder="选择商品"
                  suffixIcon={<i className="iconfont icon-gengduo"/>}
                  open={false}
                  onClick={() => {
                    this.productSelect.current?.open();
                  }}
                />
              </Form.Item>
            }
            <Form.Item style={{display: 'none'}} name="productId">
              <Input/>
            </Form.Item>
          </Form>
        </div>
      </Layout>

      <Layout.FootBar>
        <Space>
          <Button type="primary" onClick={this.clickSave}>保存</Button>
          <Button onClick={this.clickSaveAndCreate}>保存并新增</Button>
        </Space>
      </Layout.FootBar>

      <ProductDialog rowSelectType="radio"  ref={this.productSelect} onOk={data => {
        this.form?.setFieldsValue({
          productName: data[0].productName,
          productId: data[0].productId,
        });
      }}/>
    </Layout.Page>;
  }

  private validate = async (): Promise<any> => {
    try {
      const values = await this.form?.validateFields().catch(e => {});
      if (!values) {
        return Promise.reject();
      }

      if (!values) return Promise.reject();
      return apis['coupon.full.create'].post({
        couponName: values.couponName,
        couponType: values.couponType,
        fullAmount: values.fullAmount + '',
        minusAmount: values.minusAmount + '',
        limitGetCount: this.state.limitGetCount,
        limitGiveCount: this.state.limitGiveCount,
        validityDates: values.validityDates,
        relevanceId: {
          PRODUCT: values.productId,
          CATEGORY: values.categoryId,
          SALE: '',
        }[values.couponType],
      });

    } catch(e) {
      return Promise.reject();
    }
  }

  private clickSave = () => {
    this.validate().then(() => {
      showSuccess.save();
      // 刷新商品券列表页
      this.props.history.close();
    })
  }

  private clickSaveAndCreate = () => {
    this.validate().then(() => {
      showSuccess.save();
      this.form?.resetFields();
      this.setState({
        giveMethod: 'NONE',
        getMethod: 'LIMIT',
        limitGiveCount: 1,
        limitGetCount: 0,
      });
      // 刷新商品券列表页
    })
  }
}
